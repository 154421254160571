import { ObjectPosition } from 'constants/styling';
import { IBlockImage } from 'types/ContentfulTypes';
import { DEFAULT_SIZE_KEY, ImageSizes } from 'types/Image';
import { mapResponsiveContentfulImages } from 'utils/contentfulUtil';
import { StyledImageBlockResponsive, StyledImageBlockResponsiveProps } from './ContentfulImageBlockResponsive.styled';

interface ContentfulImageBlockResponsiveProps extends StyledImageBlockResponsiveProps {
  className?: string;
  mediumImage?: IBlockImage;
  mobileImage?: IBlockImage;
  objectPosition?: ObjectPosition;
  priority?: boolean;
  sizes?: ImageSizes;
  wideImage?: IBlockImage;
}

const ContentfulImageBlockResponsive = ({
  className,
  mediumImage,
  mobileImage,
  objectPosition,
  priority,
  sizes = { [DEFAULT_SIZE_KEY]: 600 },
  wideImage,
  ...props
}: ContentfulImageBlockResponsiveProps) => {
  if (!(mobileImage || mediumImage || wideImage)) {
    return null;
  }

  const images = mapResponsiveContentfulImages(mobileImage, mediumImage, wideImage);

  return (
    <div>
      {images?.map(
        ({ image, size }) =>
          image && (
            <StyledImageBlockResponsive
              key={`${image?.fields?.image?.fields?.title}-${size}`}
              className={className}
              content={image}
              hasRelativeParent
              priority={priority}
              size={size}
              sizes={sizes}
              objectPosition={objectPosition}
              {...props}
            />
          ),
      )}
    </div>
  );
};

export default ContentfulImageBlockResponsive;
