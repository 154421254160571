import styled, { css } from 'styled-components';
import { breakpointUp } from 'theme';
import { breakpointDown } from 'theme/functions';
import { BREAKPOINTS } from 'constants/breakpoints';
import { BLOCK_IMAGE_SIZES, BlockImageSize } from 'constants/contentful';
import ContentfulImageBlock from '../ContentfulImageBlock/ContentfulImageBlock';

export interface StyledImageBlockResponsiveProps {
  size?: BlockImageSize;
}

export const StyledImageBlockResponsive = styled(ContentfulImageBlock)<StyledImageBlockResponsiveProps>`
  ${({ size }) => {
    switch (size) {
      case BLOCK_IMAGE_SIZES.SMALL:
        return css`
          ${breakpointUp(
            BREAKPOINTS.SM,
            css`
              display: none;
            `
          )}
        `;
      case BLOCK_IMAGE_SIZES.MEDIUM:
        return css`
          ${breakpointDown(
            BREAKPOINTS.SM,
            css`
              display: none;
            `
          )}

          ${breakpointUp(
            BREAKPOINTS.MD,
            css`
              display: none;
            `
          )}
        `;
      // Add more cases as needed
      case BLOCK_IMAGE_SIZES.WIDE:
        return css`
          ${breakpointDown(
            BREAKPOINTS.MD,
            css`
              display: none;
            `
          )};
        `;
      default:
        return null;
    }
  }}
`;
